import React, { Component } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import loadable from "@loadable/component";

import {
  deviceType
} from 'detect-it';

const CloudinaryVideo = loadable(() => import("../components/cloudinaryvideo"), {
  ssr: false, // Disables Server-Side Rendering
});


class NavLink extends Component {
  constructor(props) {
    super(props)
    this.mediaItem = React.createRef();
    this.hoverPlayVideo = this.hoverPlayVideo.bind(this);
    this.hoverPauseVideo = this.hoverPauseVideo.bind(this);
  }
  hoverPlayVideo() {
    if(deviceType === 'mouseOnly' && this.props.navItem.hoverVideo) {
      if(this.mediaItem.current) {
        this.mediaItem.current.playVideo()
      }
    } else {
      return false
    }
  }
  hoverPauseVideo() {
    if(deviceType === 'mouseOnly' && this.props.navItem.hoverVideo) {
      if(this.mediaItem.current) {
        this.mediaItem.current.pauseVideo()
      }
    } else {
      return false
    }
  }
  render() {
    const navItem = this.props.navItem

    return (
      <Link
        onMouseEnter={() => this.hoverPlayVideo()}
        to={navItem.link.url} className="nav-item inline-block text-lg desk:text-xlalt opacity-50 hover:opacity-100 lrg:pr-20"><span className="block transition-transform duration-700">{navItem.link.title}</span>
          {
            (navItem.hoverVideo || navItem.hoverImage?.localFile?.childImageSharp?.fluid) ?
              <div className="hidden lrg:block asset transition-opacity">
                {
                  (navItem.hoverVideo) ?
                    <CloudinaryVideo
                      publicUrl={navItem.hoverVideo}
                      autoPlay={false}
                      ref={this.mediaItem}
                    />
                  : ''
                }
                {/* {
                  (!navItem.hoverVideo && navItem.hoverImage?.localFile?.childImageSharp?.fluid) ?
                    <Image
                      fluid={navItem.hoverImage?.localFile?.childImageSharp?.fluid}
                      criticial={true}
                    />
                  : ''
                } */}
              </div>
            : ''
          }
      </Link>
    )


  }

}

export default NavLink
